<template>
    <div class="loading-curtain active">
        <div class="container"><img src="/images/logo.png" alt=""></div>
    </div>
</template>

<script>

export default {

};

</script>
