<template>
	<div>
		<div class="simple-title">{{ modifierList.name }} ({{ isRequired ? 'Required' : 'Optional' }})</div>
		<div v-if="isSingleSelect" class="input-wrapper">
			<!-- Radio -->
			<label v-if="!isRequired" class="input-radio">
				<input type="radio" :id="`modifier-${ modifierList.id }`" :value="null" v-model="form.radio">
				<span class="radio-checkmark"></span>
				<div class="radio-text">None</div>
			</label>
			<label v-for="modifier in modifierList.modifiers" :key="`item-${ item.id }-modifier-${ modifier.id }`" class="input-radio">
				<input type="radio" :id="`modifier-${ modifierList.id }`" :value="modifier.id" v-model="form.radio">
				<span class="radio-checkmark"></span>
				<div class="radio-text">{{ modifier.name }} <span v-if="modifier.price > 0">(+{{ modifier.price | cents }})</span></div>
			</label>
		</div>
		<div v-if="!isSingleSelect" class="input-wrapper">
			<!-- Checkbox -->
			<div v-for="modifier in modifierList.modifiers" :key="`item-${ item.id }-modifier-${ modifier.id }`" class="input-checkbox">
				<div class="checkmark-container">
					<label class="checkbox">
						<input type="checkbox" :id="`modifier-${ modifier.id }`" :value="modifier.id" v-model="form.checkboxes" :disabled="item.selections.modifiers.indexOf(modifier.id) === -1 && numSelected >= maxSelections">
						<span class="checkmark"></span>
					</label>
				</div>
				<label :for="`modifier-${ modifier.id }`" class="input-checkbox-text">{{ modifier.name }} <span v-if="modifier.price > 0">(+{{ modifier.price | cents }})</span></label>
			</div>
		</div>
	</div>
</template>

<script>

// TODO - Radio buttons are not grouping together. Probably need to have a custom form translator
// 		This may come up in the future, keep an eye on it.
import _ from 'underscore';

export default {

	props: {
		item: {
			type: Object,
			required: true,
		},
		modifierList: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			initialized: false,
			form: {
				radio: null,
				checkboxes: [],
			}
		};
	},

	computed: {

		isRequired() {
			return this.modifierList.selections.min > 0;
		},

		isSingleSelect() {
			return this.modifierList.selections.type === 'SINGLE';
		},

		numSelected() {
			return this.selectedModifiers.length;
		},

		selectedModifiers() {
			return [ ...this.item.selectedModifiers() ].filter(modifierId => this.listModifierIds.includes(modifierId));
		},

		listModifierIds() {
			return _.pluck(this.modifierList.modifiers, 'id');
		},

		maxSelections() {
			if (this.modifierList.selections.max === null) return 99;

			return this.modifierList.selections.max;
		},

	},

	watch: {
		'modifierList.id': function () {
			this.initSelections();
		},

		'form.radio': function(selectedModifierId) {
			let modifiers = [ ...this.item.selections.modifiers ];

			if (this.initialized) {
				this.listModifierIds.forEach(listModifierId => {
					if (selectedModifierId === listModifierId) {
						// Add
						modifiers.push(listModifierId);
					} else {
						// Remove
						modifiers = _.without(modifiers, listModifierId);
					}
					this.$set(this.item.selections, 'modifiers', _.unique(modifiers));
				});
			}
		},

		'form.checkboxes': function(selectedModifierIds) {
			let modifiers = [ ...this.item.selections.modifiers ];
			if (this.initialized) {
				this.listModifierIds.forEach(listModifierId => {
					if (selectedModifierIds.indexOf(listModifierId) !== -1) {
						// Add
						modifiers.push(listModifierId);
					} else {
						// Remove
						modifiers = _.without(modifiers, listModifierId);
					}
					this.$set(this.item.selections, 'modifiers', _.unique(modifiers));
				});
			}
		},
	},

	created() {
		this.initSelections();
	},

	methods: {

		initSelections() {
			this.form.radio = null;
			this.form.checkboxes = [];
			if (this.isSingleSelect) {
				this.form.radio = this.selectedModifiers[0] || null;
			} else {
				this.form.checkboxes = this.selectedModifiers;
			}

			this.$nextTick(() => {
				this.initialized = true;
			});
		},

	},

};

</script>
