<template>
	<div class="menu-item">
		<div class="wrapper">
			<div class="photo" v-if="item.primaryPhoto()"><img :src="item.primaryPhoto().url"></div>
			<div class="interact">
				<div class="name">{{ item.name }}</div>
				<div class="price">{{ item.outputPrice() }}</div>
				<div class="description">{{ item.description }}</div>
				<div class="add-to-cart" v-if="requiresCustomization">
					<div class="button" v-if="canOrder">
						<a v-if="!showAlreadyAddedToCart" href @click.prevent="$emit('select', { type: 'customize' })">Make Selections<span class="fal fa-hand-point-up"></span></a>
					</div>
				</div>
				<div class="add-to-cart" v-else>
					<div class="quantity" v-if="canOrder">
						<select v-model="form.quantity">
							<option>1</option>
							<option>2</option>
							<option>3</option>
							<option>4</option>
							<option>5</option>
							<option>6</option>
							<option>7</option>
							<option>8</option>
							<option>9</option>
						</select>
					</div>
					<div class="button" v-if="canOrder">
						<a href @click.prevent="$emit('select', { type: 'add', quantity: parseInt(form.quantity, 10) })">Add<span class="fal fa-mug"></span></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import MerchantLocationStatus from '@/mixins/MerchantLocationStatus';
import { EventBus } from '@/eventBus';

export default {

	props: {
		item: {
			type: Object,
			required: true,
		},
		canOrder: {
			type: Boolean,
			required: true,
		},
	},

	mixins: [ MerchantLocationStatus ],

	data() {
		return {
			form: {
				quantity: 1,
			},
			showAddedNotice: false,
			timeoutRef: null,
		};
	},

	computed: {

		...mapGetters([ 'cart', 'cartLocationId' ]),

		requiresCustomization() {
			return !(this.item.variants.length === 1 && this.item.modifier_lists.length === 0);
		},

		showAlreadyAddedToCart() {
			return (!this.requiresCustomization && this.cart && this.cart.map(item => item.id).includes(this.item.id));
		},

	},

	mounted() {
		EventBus.$on('addedToCart', ({ item }) => {
			if (item.hash === this.item.hash) {
				this.$store.dispatch('addToast', 'Item added to cart');
				this.showAddedNotice = true;
				clearTimeout(this.timeoutRef);

				this.timeoutRef = setTimeout(() => {
					this.showAddedNotice = false;
				}, 10000);
			}
		});
	}
};

</script>
