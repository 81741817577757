<template>
	<div class="item-details-wrapper">
		<div class="item-details-header">
			<div class="left">{{ item.name }}</div>
			<div class="right"><a href @click.prevent="close"><span class="fal fa-times-circle"></span></a></div>
		</div>
		<form @submit.prevent="onSubmit">
			<div v-show="item" ref="content" class="item-details-content">
				<div v-if="item" class="item-details-container">
					<div class="selections-container">
						<div class="form-container">
							<form-errors :errors="errors"></form-errors>
							<div v-if="item.variants.length > 1">
								<div class="simple-title">Required Selections</div>
								<field-errors v-if="submitAttempted" :form="$v.form" field="variantId" name="variant"></field-errors>
								<div class="input-container">
									<label v-for="variant in item.variants" :key="`item-${ item.id }-variant-selection-${ variant.id }`" class="input-radio">
										<input type="radio" :id="`variant-${ variant.id }`" :value="variant.id" v-model="form.variantId">
										<span class="radio-checkmark"></span>
										<div class="radio-text">{{ variant.name }} <span v-if="variant.pricing.price > 0">{{ variant.pricing.price | cents }}</span></div>
									</label>
								</div>
							</div>
							<item-modifier-list
								v-for="modifierList in item.modifier_lists"
								:key="`item-${ item.id }-modifier-list-${ modifierList.id }`"
								:item="item"
								:modifier-list="modifierList"
							></item-modifier-list>

							<div v-if="!$v.form.$invalid" class="item-details-footer">
								<div class="left">
									<select v-model="form.quantity">
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
										<option>6</option>
										<option>7</option>
										<option>8</option>
										<option>9</option>
									</select>
								</div>
								<div class="right"><button type="submit" class="submit-button">Add <span class="fal fa-mug"></span></button></div>
							</div>
							<div class="bottom-mobile-browser-buffer"></div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>

import _ from 'underscore';
import { DRAWERS } from '@/constants';
import ItemModifierList from '../components/ItemModifierList';
import Item from '../../../../../services/Item';
import FormMixin from '@/mixins/FormMixin';
import { requiredIf } from 'vuelidate/lib/validators';

export default {

	mixins: [ FormMixin ],

	data() {
		return {
			DRAWERS,
			item: new Item({
				variants: [],
				modifier_lists: [],
			}),
			locationId: null,
			form: {
				quantity: 1,
				variantId: null,
			},
		};
	},

	validations: {
		form: {
			variantId: {
				required: requiredIf(function() {
					return this.item.variants.length > 1;
				})
			},
			selections: {
				modifiers: requiredIf(function() {
					// Check each modifier list with required (min > 0) selections, and return false if none of those have been chosen
					const invalidModifierLists = this.item.modifier_lists.filter(list => {
						if (!list.selections.min) {
							return false;
						}

						const availableChoices = list.modifiers.map(modifier => modifier.id);
						return _.intersection(availableChoices, this.item.selections.modifiers).length < list.selections.min;
					});

					return invalidModifierLists.length > 0;
				}),
			}
		},
	},

	watch: {

		'form.quantity': function(newQuantity) {
			if (newQuantity < 1) {
				this.form.quantity = 1;
			}
		},

		'form.variantId': function(newVariantId) {
			this.item.setVariant(newVariantId);
		}

	},

	mounted() {
		this.addRootListener(DRAWERS.CUSTOMIZE_ITEM + '.drawer-opened', this.onChange.bind(this));

		if (!this.item.id) {
			this.close();
		}
	},

	methods: {

		onChange(props = {}) {
			this.$refs.content.scrollTop = 0;
			this.item = props.item;
			this.form.variantId = null;
			this.form.quantity = 1;
			this.locationId = props.locationId;
			this.submitAttempted = false;
		},

		onSubmit() {
			this.submit(async () => {
				await this.$store.dispatch('addToCart', { customized: true, locationId: this.locationId, item: this.item, quantity: this.form.quantity });
				this.$nextTick(() => {
					this.item.setInitialSelections();
				});
				this.close();
			});
		},

		close() {
			this.item = new Item({
				variants: [],
				modifier_lists: [],
			});
			this.$root.$emit('close-drawer', DRAWERS.CUSTOMIZE_ITEM);
		},

	},

	components: {
		ItemModifierList,
	},

};

</script>
