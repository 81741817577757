<template>
	<div>
		<div v-if="loaded">
			<div class="location-nav">
				<div class="links">
					<div class="name"><span class="fal fa-store"></span>{{ merchant.name }}</div>
					<a href @click.prevent="clearCategory" :class="{ active: filters.category === 'all' }">Menu</a>
					<a v-for="category in categories" :key="`category-selection-${ category }`" href @click.prevent="() => onClickCategory(category)" :class="{ active: filters.category === category }">{{ category }}</a>
					<a href @click.prevent="$modal.show('location-details', { merchant })" class="details">Location Details</a>
				</div>
			</div>

			<div class="menu-items" v-for="category in visibleCategories" :key="`category-listing-${ category }`">
				<menu-item v-for="item in itemsByCategory[category]" :key="`item-${ item.id }`" :item="item" @select="(options) => onSelectItem(item, options)" :can-order="isLocationOpen"></menu-item>
			</div>

			<drawer :name="DRAWERS.CUSTOMIZE_ITEM">
				<no-ssr><customize-item-drawer></customize-item-drawer></no-ssr>
			</drawer>
		</div>
		<loading-full-screen v-if="!loaded"></loading-full-screen>
	</div>
</template>

<script>

import _ from 'underscore';
import { mapGetters } from 'vuex';
import Item from '@/services/Item';
import CustomizeItemDrawer from './drawers/CustomizeItemDrawer';
import MenuItem from './components/MenuItem';
import { DRAWERS } from '@/constants';
//import MerchantStarRating from '../../../components/MerchantStarRating';
import MerchantLocationStatus from '../../../../mixins/MerchantLocationStatus';
import LoadingFullScreen from '../../../components/LoadingFullScreen.vue';
//import LocationBannerImage from '../../../components/LocationBannerImage';

export default {

	name: 'BrowseLocationItemsPage',

	mixins: [ MerchantLocationStatus ],

	data() {
		return {
			DRAWERS,
			loaded: false,
			failed: false,
			merchant: {},
			items: [],
			filters: {
				category: this.$route.params.category || 'all',
			},
			wasClosedAtLoad: false,
		};
	},

	computed: {


		...mapGetters([ 'cart', 'cartLocationId' ]),

		categories() {
			let allCategories = _.pluck(this.items, 'category').map(category => {
				return category || 'Other';
			});
			let categories = _.unique(allCategories);
			if (allCategories.length !== this.items.length) {
				categories.push(null);
			}

			return categories;
		},

		visibleCategories() {
			if (this.filters.category !== 'all') {
				return [ this.filters.category ];
			}

			return this.categories;
		},

		itemsByCategory() {
			return _.groupBy(this.items, ({ category }) => category || 'Other');
		},

	},

	mounted() {
		this.load(true);
	},

	methods: {

		onSelectItem(item, options = {}) {
			if (this.cart.length > 0 && this.cartLocationId != this.merchant.id) {
				this.$modal.show('start-fresh-cart', {
					onAccept: () => this.selectItem(item, options),
				});
			} else {
				this.selectItem(item, options);
			}
		},

		selectItem(item, options = {}) {
			switch (options.type) {
				case 'add':
					// Add to Order immediately
					this.$store.dispatch('addToCart', {
						locationId: this.$route.params.locationId,
						item,
						quantity: options.quantity || 1,
					});
					item.setInitialSelections();
					break;
				case 'customize':
				default:
					this.$root.$emit('open-drawer', DRAWERS.CUSTOMIZE_ITEM, { locationId: this.$route.params.locationId, item: new Item(item.item) });
					break;
			}
		},

		async load(initialLoad = false) {
			try {
				const { businessSlug, locationId } = this.$route.params;
				const [{ data: merchantData }, { data: itemsData }] = await Promise.all([
					this.$api.get(`/browse/${businessSlug}/locations/${locationId}/details`),
					this.$api.get(`/browse/${businessSlug}/locations/${locationId}/items`),
				]);

				if (initialLoad) {
					this.startStatusChecker(merchantData, (status) => this.onStatusChange(status));
				}

				this.merchantIsActive = merchantData.is_active;
				this.wasClosedAtLoad = (initialLoad && !merchantData.is_active);

				this.merchant = merchantData;
				this.items = itemsData.data.map(item => new Item(item));

				this.loaded = true;
			} catch(e) {
				this.$store.dispatch('errorToast', 'Unable to load page');
			}
		},

		onStatusChange({ wasOpen, isOpen }) {
			this.$set(this.merchant, 'is_active', isOpen);
			if ((wasOpen || !this.wasClosedAtLoad) && !isOpen) {
				// Was open previously and is no longer open - show modal
				this.$modal.show('location-closed', { locationId: this.merchant.id });
			} else if (!wasOpen && isOpen) {
				this.$modal.hide('location-closed');
			}
		},

		onClickCategory(category) {
			if (this.filters.category === category) return;

			this.$set(this.filters, 'category', this.filters.category === category ? null : category);
			this.$router.replace({ name: 'browse-location-items', params: { ...this.$route.params, category: category } });
		},

		clearCategory() {
			if (this.filters.category === 'all') return;

			this.filters.category = 'all';
			let params = { ...this.$route.params };
			delete params.category;

			this.$router.replace({ name: 'browse-location-items', params });
		}

	},

	components: {
		//MerchantStarRating,
		CustomizeItemDrawer,
		MenuItem,
		LoadingFullScreen,
		//LocationBannerImage,
	},

};

</script>
